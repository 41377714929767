

// export const schedulerTrackServiceBaseUrl = 'https://ziyobrpjcf.execute-api.us-west-2.amazonaws.com/Dev'; 
// export const schedulerSharedServiceBaseUrl = ''; 
// export const OKTA_CLIENT_ID = "0oa11fqd7tahbgmUU0h8";
// export const OKTA_ISSUER_URL = "https://twdev.oktapreview.com/oauth2/default";
// export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
// export const OKTA_URL = "https://twdev.oktapreview.com";
// export const IDLE_TIMEOUT = "60";
// export const IDLE_WARN_TIMEOUT = "30";



export const SchedulerServiceBaseUrl = 'https://xqalksgijj.execute-api.us-west-2.amazonaws.com/dev';  
// export const SchedulerServiceBaseUrl = 'https://ziyobrpjcf.execute-api.us-west-2.amazonaws.com/Dev'; 
export const schedulerSharedServiceBaseUrl = ''; 
export const OKTA_CLIENT_ID = "0oa1c9f5m85bGAzOo0h8";
export const OKTA_ISSUER_URL = "https://ssodev.wbd.com/oauth2/default";
export const OKTA_REDIRECT_URL = window.location.origin + '/callback';
export const OKTA_URL = "https://ssodev.wbd.com";
export const IDLE_TIMEOUT = "60";
export const IDLE_WARN_TIMEOUT = "30";
export const FULL_CALENDAR_KEY="0273663870-fcs-1663942375"

